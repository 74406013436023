import React, { Fragment } from 'react';
import { GOOGLE_MAPS_LINK, GOOGLE_EMBED_LINK } from '@/constants';
import { styWrapper } from './styles';

function LocationSection() {
  return (
    <Fragment>
      <div id="fh5co-couple-story" className="fh5co-section-gray" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
              <h2 className="main-font">event location</h2>
              <p className="sub-title">
                <a
                  href={GOOGLE_MAPS_LINK}
                  title="click to see on Google Maps"
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: '#ffff' }}
                >
                  <strong>approximate address</strong>
                </a>{' '}
                <br />
                exact address in your email confirmation
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <iframe
                src={GOOGLE_EMBED_LINK}
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: '0' }}
                allowFullScreen
                aria-hidden="false"
                tabIndex="0"
                title="Google Maps -  Caitlyn & Chris Wedding Party"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default React.memo(LocationSection);
