import { css } from '@emotion/core';
import Background from '@assets/images/ai-wedding-location.jpg';

export const styWrapper = css`
  // background-image: url(${Background});
  // background-size: cover;
  // background-position: center;
  background: #333333;
  width: 100%;
  min-height: 70vh;
  color: #fff !important;

  @media screen and (max-width: 400px) {
    .sub-title {
      font-size: 16px;
      margin: 0 0 8px 0;
    }

    p {
      font-size: 16px;
    }
  }
`;
