import { css } from '@emotion/core';
// import Background from '@assets/images/ai-wedding-content.jpg';

export const styWrapper = css`
  font-family: 'Playfair Display', serif;
  background: rgba(51, 51, 0, 1) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  // background: rgba(0, 0, 0, 0);

  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .form-row input[type='text'],
  .form-row input[type='email'] {
    flex: 1;
    margin-right: 10px;
  }

  .form-row input[type='text']:focus,
  .form-row input[type='email']:focus {
    outline: none;
  }

  .form-row label {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 1.5;
  }

  input[type='text'],
  input[type='email'] {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  input {
    color: black;
  }

  label {
    display: block;
    color: black;
  }

  h2 {
    font-size: 66px;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Playfair Display', cursive !important;
  }

  button {
    background-color: #f9a1bc; /* Pastel pink */
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }

  button:hover {
    background-color: #f88394; /* Darker pastel pink */
  }

  button:disabled {
    background-color: #808080; /* Dark gray */
    cursor: not-allowed;
  }

  button:disabled:hover {
    background-color: #808080; /* Dark gray */
  }
`;

export const styFlex = css`
  display: flex;
  justify-content: center;
`;
