import React, { useState } from 'react';
import { styWrapper } from './styles';
import { Checkbox } from 'pretty-checkbox-react';
import '@djthoms/pretty-checkbox';

function ConfirmationSection() {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    details: '',
    plusOneFirstName: '',
    plusOneLastName: '',
    isBringingPlusOne: false,
    doesNeedTransporation: false,
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleTransporationChange = (e) => {
    const { name, type, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('submitting', formData);

    try {
      // Make a POST request to the serverless function
      const response = await fetch('/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const isFormValid = () => {
    return (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      (!formData.isBringingPlusOne || (formData.plusOneFirstName && formData.plusOneLastName))
    );
  };

  return (
    <div id="fh5co-started" className="fh5co-bg" css={styWrapper}>
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
            <h2 style={{ fontFamily: 'Playfair Display,serif !important' }}>RSVP</h2>
            <p>
              Please RSVP latest by April 1 2024. <br />
              We can't wait to celebrate with you! 🎉
            </p>
            {isSubmitted ? (
              <h3>
                you should see an email confirmation shortly <br /> see you there 🤗
              </h3>
            ) : (
              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <input type="text" name="firstName" placeholder="First Name" onChange={handleChange} required />
                  <input type="text" name="lastName" placeholder="Last Name" onChange={handleChange} required />
                </div>
                <div className="form-row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: '30px' }}>
                    <Checkbox name="isBringingPlusOne" onChange={handleChange} style={{ marginTop: '-50px' }} />
                    <label style={{ whiteSpace: 'nowrap' }}>Another One?</label>
                  </div>
                </div>
                {formData.isBringingPlusOne && (
                  <div className="form-row">
                    <input type="text" name="plusOneFirstName" placeholder="+1 First Name" onChange={handleChange} />
                    <input type="text" name="plusOneLastName" placeholder="+1 Last Name" onChange={handleChange} />
                  </div>
                )}
                <div className="form-row">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email (we'll send you a confirmation email to here)"
                    onChange={handleChange}
                    required
                  />
                </div>
                <input
                  type="text"
                  name="details"
                  placeholder="Any additional details (song requests, dietary restrictions, etc)"
                  onChange={handleChange}
                  maxLength={100}
                />
                <div className="form-row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      name="doesNeedTransporation"
                      onChange={handleTransporationChange}
                      style={{ marginTop: '-50px' }}
                    />
                    <label>Need a Ride?</label>
                  </div>
                </div>
                <button type="submit" disabled={!isFormValid()}>
                  RSVP
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ConfirmationSection);
