/**
 * Link
 */
export const GOOGLE_CALENDAR_LINK = `https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=NDR2cWxiZDhjZGZ0MG9nNnNmdTAyNHF2YzkgY2hyaWlpc2xlQG0`;
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/bMVcVNib1KjeUhsq5`;
export const GOOGLE_EMBED_LINK = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2558.30846631918!2d-122.95956682289363!3d50.1179495612482!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54873cb3a370f291%3A0xbb05b150abf868e7!2sVillage%20Park%2C%20Whistler%2C%20BC%20V0N%201B0!5e0!3m2!1sen!2sca!4v1707793743999!5m2!1sen!2sca`;
/**
 * Wedding time
 */
export const EPOCH_START_EVENT = 1726362000;
export const EPOCH_END_EVENT = 1726380000;
export const UTC_WEDDING_TIME = '2024-09-14:07:00:00Z';
