import React from 'react';
import { styWrapper } from './styles';

function ContentSection() {
  return (
    <div id="fh5co-content" className="fh5co-bg" css={styWrapper}>
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <h2 style={{ color: 'white', fontFamily: 'Playfair Display,cursive !important' }}> Q & A</h2>
            <ul
              style={{
                listStyleType: 'circle',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingLeft: '20px',
              }}
            >
              <li style={{ marginBottom: '10px' }}>Dress to Impress</li>
              <li style={{ marginBottom: '10px' }}>
                We love kids but we encourage you to take a night off - you deserve it
              </li>
              <li style={{ marginBottom: '10px' }}>
                Your presence is our present, but if you're stubborn we also love red envelopes
              </li>
              <li style={{ marginBottom: '10px' }}>
                Please RSVP in the form below - make sure you let us know if you requirement transportation assistance
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ContentSection);
